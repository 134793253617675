import React from 'react'
import {graphql} from "gatsby"
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function NotFoundPage ({data}) {
  console.log(data)
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export const query = graphql`
    query {
        allFile {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `
